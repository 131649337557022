import { Form } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }
`;

export const GeneralTab = ({ onCreate, onUpdate, onDelete, onFinally }) => {
  const { Components } = Form.useFormContext();

  const { Name, SaveButton, CancelButton } = Components;

  return (
    <Styled className={cn('general-tab')}>
      <Name />
      <div className="actions">
        <CancelButton onCancel={onFinally} enabled />
        <SaveButton onCreate={onCreate} onUpdate={onUpdate} onFinally={onFinally} />
      </div>
    </Styled>
  );
};
