import common from '../common';
import errors from './errors';
import themes from './theme';

export default {
  ...common,

  companies: 'Companies',
  profiles: 'Profiles',
  apps: 'Apps',
  roles: 'Roles',
  createRole: 'Create Role',
  permissions: 'Permissions',
  status: 'Status',
  services: 'Services',
  showAll: 'Show All',
  hideAll: 'Hide All',
  undoChanges: 'Undo Changes',
  resetColumns: 'Reset Columns',

  displayRowCount: `Displaying {count, plural, 
    =0 {0 rows} 
    one {# row} 
    other {# rows} 
  }`,

  columnFilters: 'Column Filters',

  type: 'Type',
  id: 'ID',
  hostname: 'Hostname',
  name: 'Name',
  apiKey: 'API Key',
  origin: 'Origin',
  servicesListHeader: 'Services',
  profilesListHeader: 'Profiles',
  'add-services': 'Add Service',
  'add-profiles': 'Add Profile',
  servicesEditor: 'Service Editor',
  profileEditor: 'Profile Editor',

  add: 'Add',
  cancel: 'Cancel',

  crn: 'CRN',
  timeZone: 'Time Zone',
  modifyDate: 'Last Modified',
  active: 'Active',
  entitiesListHeader: 'Companies',
  'add-entities': 'Add Company',
  entitiesEditor: 'Company Editor',
  company: 'Company',

  family_name: 'Last Name',
  given_name: 'First Name',
  email: 'Email',
  verified: 'Verified',
  createDate: 'Registered On',
  'custom:eulaVersion': 'EULA Version',
  'custom:privacyVersion': 'Privacy Version',
  enabled: 'Enabled',

  notAuthorized: 'Not Authorized',

  general: 'General',
  isTestEntity: 'Test Company',
  password: 'Password',
  createProfile: 'Create Profile',
  createEntity: 'Create Company',
  users: 'Users',
  profilesEditor: 'Profiles Editor',
  delete: 'Delete',
  update: 'Update',

  save: 'Save',
  visible: 'Visible',
  hidden: 'Hidden',
  gridPreferences: 'Grid Preferences',
  partners: 'Partners',
  'add-partners': 'Add Partner',
  partnersListHeader: 'Partners',
  partnersEditor: 'Partners Editor',

  register: 'Register',
  registerInstructions: 'Please fill out this form to finalize your registration.',

  code: 'Verification Code',
  username: 'Username',
  familyName: 'Last Name',
  givenName: 'First Name',
  phoneNumber: 'Phone Number',
  verifyPassword: 'Verify Password',

  'password-requirements':
    'Your password must be at least 8 characters, contain at least 1 uppercase, 1 lowercase, 1 number and 1 special character.',

  required: 'Required',

  success: 'Success!',
  'registration-success': 'Your registration was successful.',
  'post-registration-instructions': 'You\'ve completed the registration process. You may now log in.',

  errors,
  themes,
};
