import { Localization, Typography } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  margin: 10px 0;
`;

export const Header = props => {
  return (
    <Styled className={cn('header')}>
      <Typography.H3>
        <Localization.Translate stringId="register" data-testid="register-heading" />
      </Typography.H3>
    </Styled>
  );
};
