import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { API, ConnexDesignSystem } from 'connex-cds';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { InitializeApi } from './api/InitializeApi';
import messages from './i18n';
import AuthenticatedRoutes from './views/authenticated-routes/AuthenticatedRoutes';
import { RedirectToEntity } from './views/authenticated-routes/RedirectToEntity';
import { Landing } from './views/Landing';
import { RegisterRoutes } from './views/register/Register';
import { Roles } from './views/roles/Roles';
import { Users } from './views/users/Users';

API.setSource('company-admin');

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 5000 } },
});

const App = () => {
  return (
    <InitializeApi>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ConnexDesignSystem messages={messages} initialTimeZone="America/Chicago" appId="company-admin">
            <Routes>
              <Route path="/register/:entityRef/:data/*" element={<RegisterRoutes />} />
              <Route path="" element={<AuthenticatedRoutes />}>
                <Route path="" element={<RedirectToEntity />}>
                  <Route path=":entityRef">
                    <Route path="" element={<Landing />} />
                    <Route path="users" element={<Users />} />
                    <Route path="roles/*" element={<Roles />} />
                  </Route>
                </Route>
              </Route>
            </Routes>
          </ConnexDesignSystem>
        </QueryClientProvider>
      </BrowserRouter>
    </InitializeApi>
  );
};

export default App;
