import { css } from 'styled-components';

export default css`
  width: 100%;
  text-align: center;
  .wrapper {
    margin-left: auto;
    margin-right: auto;
    min-width: 300px;
    max-width: 600px;
    .container {
      margin: 10px;
      .container.password {
        padding-top: 10px;
        text-align: left;
        margin: 0;
        h4 {
        }
      }
      .button-section {
        margin: 20px 0;
        display: flex;
        & > * {
          flex: 1;
        }
      }
    }
  }
`;
