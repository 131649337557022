const testWindowLocation = {
  ancestorOrigins: {
    0: 'https://connex.develop.commandalkon.io',
  },
  href: 'https://mt.develop.commandalkon.io/2851817f-1c0f-40e4-81d1-5c8154083d44/driver/asdf/ticketDetail',
  origin: 'https://mt.develop.commandalkon.io',
  protocol: 'https:',
  host: 'mt.develop.commandalkon.io',
  hostname: 'mt.develop.commandalkon.io',
  port: '',
  pathname: '/2851817f-1c0f-40e4-81d1-5c8154083d44/driver/asdf/ticketDetail',
  search: '',
  hash: '',
};

export const getLocationInfo = () => {
  const { hostname, port } = window?.location;

  if (hostname === 'localhost') return { region: 'localhost', port };

  const split = hostname.split('.');
  return { service: split[0], region: split[1] || 'xx' };
};
