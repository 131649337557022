import { Form } from 'connex-cds';
import { GeneralTab } from './GeneralTab';
import { PermissionsTab } from './PermissionsTab';

export default {
  tabs: [
    {
      stringId: 'general',
      testId: 'general-tab',
      component: <GeneralTab />,
    },
    {
      stringId: 'permissions',
      testId: 'permissions-tab',
      component: <PermissionsTab />,
      allowedModes: Form.MODES.EDIT,
    },
  ],
  translate: [{ getPath: 'stringId', setPath: 'name' }],
};
