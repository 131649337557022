import React from 'react';
import { usePostMessageContext } from '../../post-message/PostMessage';
import { cacheItem, getCachedItem } from '../../util/cache';
import { usePostMessageHandler } from '../../util/usePostMessageHandler';
import { UserContext } from './UserContext';

export const USER_KEY = '73804c78-c9d6-4673-8ad0-b96d1ef08ff2';

export const UserProvider = ({ children, connex }) => {
  const cachedUser = getCachedItem(USER_KEY);
  const { sendMessage } = usePostMessageContext();

  const [user, _setUser] = React.useState(cachedUser ? JSON.parse(cachedUser) : null);

  const sendUser = React.useCallback(() => {
    if (connex) {
      sendMessage({ user });
    }
  }, [connex, sendMessage, user]);

  React.useEffect(() => {
    if (!connex) {
      sendMessage({ type: 'user-rq' });
    }
  }, [connex, sendMessage]);

  const handleMessageEvent = React.useCallback(
    event => {
      const message = event?.data;
      if (!connex) {
        if (message?.user) {
          _setUser(message?.user);
        }
      } else {
        if (message?.type === 'user-rq') {
          sendUser();
        }
      }
    },
    [connex, sendUser]
  );

  usePostMessageHandler(handleMessageEvent);

  const setUser = React.useCallback(user => {
    if (user) {
      const apps = [].concat([...(user?.apps || [])]); //['mt'];

      if (user?.email?.endsWith?.('commandalkon.com')) {
        apps.push({ name: 'Platform Admin', crn: 'admin' });
        apps.push({ name: 'Company Admin', crn: 'company-admin' });
      }

      user.apps = apps;

      cacheItem(USER_KEY, JSON.stringify(user));
      _setUser(user);
    }
  }, []);

  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};
