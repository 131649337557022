import cn from 'classnames';
import { Core, Drawer, Form } from 'connex-cds';
import React from 'react';
import { useParams } from 'react-router-dom';
import { users } from '../../../api';
import { useListRoles } from '../../../query-hooks/roles';

export const View = () => {
  const { Components, values, isValid } = Form.useFormContext();
  const { closeDrawer } = Drawer.useDrawerContext();
  const [busy, setBusy] = React.useState(false);

  const { entityRef } = useParams();

  const roleQuery = useListRoles();

  const handleClick = React.useCallback(async () => {
    setBusy(true);
    const response = await users.inviteUser({
      entityRef,
      user: { username: values.emailOrPhoneNumber, roleRef: values?.role?.roleRef },
    });

    setBusy(false);

    if (response?.success === 'ok') {
      closeDrawer();
    }
  }, [values]);

  return (
    <div className={cn('view')}>
      <Components.EmailOrPhoneNumber />
      <Components.Role options={roleQuery.data} busy={roleQuery.isLoading} />
      <div className="actions">
        <Core.Button
          onClick={handleClick}
          testId="invite-button"
          stringId="inviteUser"
          disabled={!isValid}
          type="primary"
          loading={busy}
        >
          Invite
        </Core.Button>
      </div>
    </div>
  );
};
