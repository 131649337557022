import { Form } from 'connex-cds';

const { FIELD_TYPES } = Form;

export default {
  path: 'roles',
  labelStringId: 'roles',
  testId: 'roles',
  keyPath: 'crn',
  fields: [{ path: 'name', labelStringId: 'name', testId: 'name', dataType: FIELD_TYPES.string }],
};
