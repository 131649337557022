import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { users } from '../api';

export const useListUsers = () => {
  const { entityRef } = useParams();

  const query = useQuery({
    queryKey: ['users', entityRef],
    queryFn: () => users.listUsers({ entityRef }),
    staleTime: Infinity,
  });

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  return query;
};

export const useUpdateUser = () => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();

  const queryKey = ['users', entityRef];

  const mutation = useMutation({
    mutationFn: profile => users.updateUser({ entityRef, profile }),
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      const newData = previousItem?.map?.(item => (item.crn === newItem.crn ? newItem : item));
      queryClient.setQueryData(queryKey, newData);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation.mutateAsync;
};
