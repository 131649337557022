import cn from 'classnames';
import { Form } from 'connex-cds';
import React from 'react';
import { useNavigate } from 'react-router';
import { Route, Routes, useParams } from 'react-router-dom';
import styled from 'styled-components';
import config from './config';
import style from './style';
import { Success } from './Success';
import { useRegister } from './useRegister';
import { View } from './View';

const Styled = styled.div`
  ${style}
`;

export const RegisterRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Register />} />
      <Route path="success" element={<Success />} />
    </Routes>
  );
};

export const Register = () => {
  const { busy, register } = useRegister();
  const params = useParams();
  const navigate = useNavigate();

  const handleSubmit = React.useCallback(
    profile => {
      const trimmed = { ...profile, code: !!profile?.code?.trim ? profile.code.trim() : profile?.code };
      return register({ profile: trimmed, eula: 'accepted', terms: 'accepted' }).then(() => {
        navigate('success');
      });
    },
    [navigate, register]
  );

  const initialValues = React.useMemo(() => {
    const [code, username] = params?.data
      ? atob(params?.data)?.split?.('::') || [undefined, undefined]
      : [undefined, undefined];

    return {
      code: code || '',
      username: username || '',
      lastName: '',
      firstName: '',
      phoneNumber: '',
      password: '',
      passwordVerify: '',
    };
  }, [params?.data]);

  return (
    <Styled className={cn('register')}>
      <Form.FormProvider config={config} onSubmit={handleSubmit} initialValues={initialValues}>
        <View busy={busy} />
      </Form.FormProvider>
    </Styled>
  );
};
