import { Localization } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  text-align: left;
  padding: 0 20px;
`;

export const Instructions = props => {
  return (
    <Styled className={cn('instructions')}>
      <Localization.Translate stringId="registerInstructions" data-testid="register-instructions" />
    </Styled>
  );
};
