import { Auth } from 'connex-cds';
import React from 'react';
import { useParams } from 'react-router-dom';
import menuConfig from '../../menu-config';

const { IFrameAuthenticated } = Auth;

const AuthenticatedRoutes = () => {
  const { entityRef } = useParams();

  console.log('Authenticated Routes');

  return <IFrameAuthenticated entityRef={entityRef} menuConfig={menuConfig} />;
};

export default AuthenticatedRoutes;
