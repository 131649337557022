import React from 'react';
import { Form } from 'connex-cds';
import { roles } from '../../query-hooks';
import config from './config';
import { RolesEditor } from './RolesEditor';

const { DETAIL_TYPES, ListAndDetail } = Form;

export const Roles = () => {
  const listRoles = roles.useListRoles();
  const createRole = roles.useCreateRole();
  const updateRole = roles.useUpdateRole();

  return (
    <ListAndDetail
      detailType={DETAIL_TYPES.VIEW}
      Editor={RolesEditor}
      showHeader={false}
      config={config}
      listQuery={listRoles}
      createQuery={createRole}
      updateQuery={updateRole}
    />
  );
};
