import cn from 'classnames';
import { Drawer, Form, Localization, Typography } from 'connex-cds';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { DETAIL_TYPES } from '../../for_sdk/list-and-detail/ListAndDetail';
import { useListAndDetailContext } from '../../for_sdk/list-and-detail/ListAndDetailProvider';
import style from './style';
import tabsConfig from './tabs/tabs-config';

const Styled = styled.div`
  ${style}
`;

export const RolesEditor = ({ updateQuery, createQuery, context }) => {
  const { values, mode } = Form.useFormContext();
  const { closeDrawer } = Drawer.useDrawerContext();
  const { detailType } = context;
  const navigate = useNavigate();

  const onFinally = React.useCallback(() => {
    const behaviors = {
      [DETAIL_TYPES.VIEW]: () => navigate(`..`), // TODO: Switch to edit mode and stay put.
      [DETAIL_TYPES.DRAWER]: closeDrawer,
      [DETAIL_TYPES.FULL_WIDTH_DRAWER]: closeDrawer,
      [DETAIL_TYPES.MODAL]: () => {},
    };
    behaviors[detailType]?.();
  }, [closeDrawer, detailType, navigate]);

  const handleCreate = React.useCallback(
    role =>
      createQuery?.(role)?.then(response => {
        onFinally?.();
        return response;
      }),
    [createQuery, onFinally]
  );

  const handleUpdate = React.useCallback(
    role => {
      return updateQuery?.(role)?.then(response => {
        onFinally?.();
        return response;
      });
    },
    [onFinally, updateQuery]
  );

  return (
    <Styled className={cn('company-editor')}>
      <Typography.H2>
        {mode === 'edit' ? (
          values?.name
        ) : (
          <Localization.Translate stringId="createRole" data-testid="create-role-header" />
        )}
      </Typography.H2>
      <hr />
      {/*Props, other than config, are passed to each underlying tab component.*/}
      <Form.Tabs config={tabsConfig} onCreate={handleCreate} onUpdate={handleUpdate} onFinally={onFinally} />
    </Styled>
  );
};
